<template>
  <div class="flex w-full">
    <input v-model="searchString" type="text" id="searchItem" name="searchItem" placeholder="Suchen..." class="w-full py-2 px-2">
    <button v-if="searchString" class="bg-red-500 text-white ml-1 py-2 px-2 rounded" v-on:click="clear">Löschen</button>
  </div>
</template>

<script>


export default {
  name: 'SearchItem',
  components: {
  },
  props: {
  },
  data () {
    return {
      searchString: ""
    }
  },
  watch: {
    searchString: function(entry) {
      this.$emit('search-event', {"text": entry});
    }
  },
  methods: {
    clear() {
      this.searchString = "";
    }
  }
}
</script>

