import Vue from 'vue'
import VueRouter from 'vue-router'
import VegiView from '@/views/VegiView.vue'
import MeatView from '@/views/MeatView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'vegi',
    component: VegiView
  },
  {
    path: '/meat',
    name: 'meat',
    component: MeatView
  }
]

const router = new VueRouter({
  routes
})

export default router
