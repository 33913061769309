<template>
  <div class="flex flex-col min-h-screen">
    <main class="flex-grow">
    </main>
    <footer class="sticky bottom-0 p-2 bg-white">
      <div class="p-2 flex justify-end">
        <MenuItem />
      </div>
    </footer>
  </div>
</template>

<script>
import MenuItem from '@/components/MenuItem.vue'

export default {
  name: 'MeatView',
  components: {
    MenuItem
  },
}
</script>