<template>
    <div v-show="show()" class="p-1">
      <div class="flex border rounded">
        <img class="transform scale-100 rounded-l h-28 w-28" v-bind:src="'img/food/' + vegiItem.image">
        <div class="w-full">
          <!-- div class="w-full" v-bind:class="[isThisMonthAvailableInList() ? 'bg-green-200' : 'bg-red-200']" -->
          <div class="p-2 pb-0 uppercase text-gray-600 font-bold">
            <span v-html="name"></span>
          </div>
          <div class="pl-2 text-gray-600 text-xs">
            <span v-html="synonymsText"></span>
          </div>
          <div class="grid grid-cols-6 text-xs text-gray-600 text-center p-2">
            <MonthItem :month=0 :vegiItem="vegiItem" :display-harvest=displayHarvest />
            <MonthItem :month=1 :vegiItem="vegiItem" :display-harvest=displayHarvest />
            <MonthItem :month=2 :vegiItem="vegiItem" :display-harvest=displayHarvest />
            <MonthItem :month=3 :vegiItem="vegiItem" :display-harvest=displayHarvest />
            <MonthItem :month=4 :vegiItem="vegiItem" :display-harvest=displayHarvest />
            <MonthItem :month=5 :vegiItem="vegiItem" :display-harvest=displayHarvest noBorderRight />
            <MonthItem :month=6 :vegiItem="vegiItem" :display-harvest=displayHarvest noBorderBottom />
            <MonthItem :month=7 :vegiItem="vegiItem" :display-harvest=displayHarvest noBorderBottom />
            <MonthItem :month=8 :vegiItem="vegiItem" :display-harvest=displayHarvest noBorderBottom />
            <MonthItem :month=9 :vegiItem="vegiItem" :display-harvest=displayHarvest noBorderBottom />
            <MonthItem :month=10 :vegiItem="vegiItem" :display-harvest=displayHarvest noBorderBottom />
            <MonthItem :month=11 :vegiItem="vegiItem" :display-harvest=displayHarvest noBorderRight noBorderBottom />
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import MonthItem from './MonthItem.vue'
import dayjs from 'dayjs'

export default {
  name: 'VegiItem',
  components: {
    MonthItem
  },
  props: {
    vegiItem: Object,
    selectedVegi: String,
    displayOnlyAvailable: Boolean,
    displayHarvest: Boolean,
    isFiltering: Boolean,
    filteredVegis: Array
  },
  data () {
    return {
      name: this.vegiItem.name,
      synonymsText: this.vegiItem.synonymsText
    }
  },
  created: function() {
    dayjs.locale('de');
  },
  computed: {
    thisMonth: function() {
      // dayjs().format('M') returns 1-12
      return dayjs().format('M') - 1;
    }
  },
  methods: {
    getImage() {
      return "background-image: url('img/food/" + this.vegiItem.image + "')";
    },
    show() {
      // check if we are filtering
      if (this.isFiltering && this.filteredVegis.length > 0) {
        // check if this item is in the list of filtered items
        const found = this.filteredVegis.find(element => element.name === this.vegiItem.name);
        if (found) {
          this.name = found.highlightName ?? found.name;
          this.synonymsText = found.highlightSynonyms ?? found.synonymsText;
          return this.showContinued();
        }
        this.resetFilter();
        return false;
      }
      this.resetFilter();
      return this.showContinued();
    },
    resetFilter() {
      this.name = this.vegiItem.name;
      this.synonymsText = this.vegiItem.synonymsText;
    },
    isThisMonthAvailableInList() {
      return (this.vegiItem.availability).includes(this.thisMonth);
    },
    showContinued() {
      if (this.displayOnlyAvailable) {
        return this.isThisMonthAvailableInList() && (this.selectedVegi == this.vegiItem.type);
      }
      return this.selectedVegi == this.vegiItem.type;
    },
    displayName(filteredString, defaultString) {
      return filteredString ?? defaultString;
    }
  }
}
</script>

<style>
em {
  background-color: yellow;
}
</style>
