<template>
  <div class="border-gray-600 text-lg" v-bind:class="{
    'font-extrabold bg-green-700 text-white': isMonthAvailable() && !isHarvest(),
    'bg-green-500 text-white': isMonthAvailableInList() && !isHarvest(),
    'border-r': !noBorderRight,
    'border-b': !noBorderBottom,
    'bg-yellow-600 text-white': isHarvest()
    }">{{ monthStr }}</div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'MonthItem',
  props: {
    month: Number,
    vegiItem: Object,
    displayHarvest: Boolean,
    noBorderRight: Boolean,
    noBorderBottom: Boolean
  },
  data () {
    return {
      // dayjs().month() takes 0-11
      monthStr: dayjs().month(this.month).format('MMM')
    }
  },
  created: function() {
    dayjs.locale('de');
  },
  computed: {
    thisMonth: function() {
      // dayjs().format('M') returns 1-12
      return dayjs().format('M') - 1;
    }
  },
  methods: {
    isMonthAvailable() {
      return this.month === this.thisMonth && this.isThisMonthAvailableInList();
    },
    isThisMonthAvailableInList() {
      return (this.vegiItem.availability).includes(this.thisMonth);
    },
    isMonthAvailableInList() {
      return (this.vegiItem.availability).includes(this.month);
    },
    isHarvest() {
      return this.displayHarvest && (this.vegiItem.harvest).includes(this.month);
    }
  }
}
</script>