<template>
  <div class="flex flex-col min-h-screen">
    <main class="flex-grow">
      <VegiItem v-for="item in vegiItems" 
        :key="item.name" 
        :vegi-item="item" 
        :selected-vegi="selectedVegi.value" 
        :display-only-available=displayOnlyAvailable
        :display-harvest=displayHarvest
        :is-filtering=isFiltering
        :filtered-vegis="filteredVegis"
        />
    </main>
    <footer class="sticky bottom-0 p-2 bg-white">
      <v-select 
        :options="vegiList"
        label="text"
        :clearable=false
        :searchable=false
        v-model="selectedVegi"
        @input="writeSelectedVegi"
        append-to-body :calculate-position="withPopper"
      ></v-select>
      <div class="p-2 flex">
        <div class="flex w-full justify-between">
          <div>
            <input class="mr-2" type="checkbox" v-model="displayOnlyAvailable" @change="writeDisplayOnlyAvailable">
            <label for="checkbox" @click="toggleDisplayOnlyAvailable">Im <span class="font-bold">{{ thisMonth }}</span> erhältlich</label>
          </div>
          <div class="mr-2">
            <input class="mr-2" type="checkbox" v-model="displayHarvest" @change="writeDisplayHarvest">
            <label for="checkbox" @click="toggleDisplayHarvest">Erntezeit</label>
          </div>
        </div>
        <!-- MenuItem / -->
      </div>
      <SearchItem @search-event="filterVegiItems" />
      <div v-if="updateExists" class="flex w-full justify-end bg-gray-100 p-2 mb-1">
        <div class="py-2">
          Neue Version erhältlich
        </div>
        <div>
          <button class="bg-green-500 text-white ml-1 py-2 px-2 rounded" v-on:click="refreshApp">Update</button>
        </div>
      </div>
      <div v-if="deferredPrompt" class="flex w-full justify-end bg-gray-100 p-2">
        <div class="py-2">Als App installieren</div>
        <div><button class="bg-blue-500 text-white ml-2 py-2 px-2 rounded" v-on:click="dismiss">Später</button></div>
        <div><button class="bg-green-500 text-white ml-1 py-2 px-2 rounded" v-on:click="install">Installieren</button></div>
      </div>
    </footer>
  </div>
</template>

<script>
import VegiItem from '@/components/VegiItem.vue'
import SearchItem from '@/components/SearchItem.vue'
// import MenuItem from '@/components/MenuItem.vue'
import vSelect from "vue-select";
import axios from 'axios';
import { createPopper } from '@popperjs/core';
import dayjs from 'dayjs';
import update from '@/mixins/update'
import Cookies from "js-cookie"
import fuzzysort from "fuzzysort";

export default {
  name: 'App',
  components: {
    VegiItem,
    // MenuItem,
    vSelect,
    SearchItem
  },
  data () {
    return {
      vegiItems: [],
      filteredVegis: [],
      isFiltering: false,
      selectedVegi: { value: 'vegetable', text: 'Gemüse' },
      vegiList: [
        { "value": "vegetable", "text": "Gemüse" },
        { "value": "fruit", "text": "Früchte" },
        { "value": "herb", "text": "Kräuter" }
      ],
      displayOnlyAvailable: false,
      displayHarvest: false,
      deferredPrompt: null
    }
  },
  created() {
    dayjs.locale('de');
    this.loadData();
    // install as app
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      if (Cookies.get("food-add-to-home-screen") === undefined) {
        this.deferredPrompt = e;
      }
    });
    // install as app
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  mixins: [update],
  computed: {
    thisMonth: function() {
      return dayjs().format('MMMM');
    }
  },
  methods: {
    loadData() {
      axios.get(
        "data.json"
      ).then (response => {
        this.vegiItems = response.data;
      });
    },
    readSelectedVegi() {
      if (localStorage.getItem('selectedVegi')) {
        try {
          this.selectedVegi = JSON.parse(localStorage.getItem('selectedVegi'));
        } catch(e) {
          localStorage.removeItem('selectedVegi');
        }
      } else {
        this.writeSelectedVegi();
      }
    },
    writeSelectedVegi() {
      localStorage.setItem('selectedVegi', JSON.stringify(this.selectedVegi));
    },
    readDisplayOnlyAvailable() {
      if (localStorage.getItem('displayOnlyAvailable')) {
        try {
          this.displayOnlyAvailable = JSON.parse(localStorage.getItem('displayOnlyAvailable'));
        } catch(e) {
          localStorage.removeItem('displayOnlyAvailable');
        }
      } else {
        this.writeDisplayOnlyAvailable();
      }
    },
    writeDisplayOnlyAvailable() {
      localStorage.setItem('displayOnlyAvailable', this.displayOnlyAvailable);
    },
    toggleDisplayOnlyAvailable() {
      this.displayOnlyAvailable = !this.displayOnlyAvailable;
    },
    toggleDisplayHarvest() {
      this.displayHarvest = !this.displayHarvest;
    },
    readDisplayHarvest() {
      if (localStorage.getItem('displayHarvest')) {
        try {
          this.displayHarvest = JSON.parse(localStorage.getItem('displayHarvest'));
        } catch(e) {
          localStorage.removeItem('displayHarvest');
        }
      } else {
        this.writeDisplayHarvest();
      }
    },
    writeDisplayHarvest() {
      localStorage.setItem('displayHarvest', this.displayHarvest);
    },
    filterVegiItems(event) {
      this.isFiltering = event.text.length > 0;
      let results = fuzzysort.go(event.text, this.vegiItems, {
        keys: ["name", "synonymsText"]
      });
      results = results.map(result => {
        result.highlightName = fuzzysort.highlight(result[0], "<em>", "</em>")
        result.highlightSynonyms = fuzzysort.highlight(result[1], "<em>", "</em>")
        return result
      });

      this.filteredVegis = results.map(item => {
        return {
          name: item.obj.name,
          synonymsText: item.obj.synonymsText,
          highlightName: item.highlightName,
          highlightSynonyms: item.highlightSynonyms
        }
      });
    },
    withPopper (dropdownList, component, {width}) {
      // https://vue-select.org/guide/positioning.html#popper-js-integration
      dropdownList.style.width = width;
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top'
      });
      return () => popper.destroy();
    },
    // install as app
    async dismiss() {
      // expires in 15 days
      Cookies.set("food-add-to-home-screen", null, { expires: 15 });
      this.deferredPrompt = null;
    },
    // install as app
    async install() {
      this.deferredPrompt.prompt();
    }
  },
  mounted() {
    this.readDisplayHarvest();
    this.readSelectedVegi();
    this.readDisplayOnlyAvailable();
  }
};
</script>
