<template>
  <div>
    <div class="text-right">
      <i class="bi bi-list" v-if="!isMenuOpen" @click="onMenu"></i>
      <i class="bi bi-x" v-if="isMenuOpen" @click="onMenu"></i>
    </div>
    <div v-if="isMenuOpen" class="bg-gray-100 p-1 rounded w-full">
      <router-link to="/"><div class="hover:bg-gray-300 rounded">Vegetarisch</div></router-link>
      <router-link to="/meat"><div class="hover:bg-gray-300 rounded">Fleisch</div></router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MenuItem',
  components: {

  },
  data () {
    return {
      isMenuOpen: false
    }
  },
  props: {
    
  },
  methods: {
    onMenu () {
      this.isMenuOpen = !this.isMenuOpen;
    }
  },
}
</script>